import React from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from 'gatsby-background-image'

import { Layout } from '../components/index';
import Header from '../components/Header';
import { Link } from '../utils';
import Footer from '../components/Footer';

import { useTranslation } from "react-i18next"

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($locale: String!, $slug: String!) {
    sitePage(path: {eq: $slug}) {
      id
    }
    home: allFile(filter: {relativePath: {in: ["home/appartement.jpg", "home/autour.jpg", "home/contact.jpg", "home/infos.jpg", "home/reglement.jpg", "home/reservation.jpg", "home/plan-acces.png"]}}) {
      edges {
        node {
          relativePath
          ext
          extension
          name
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      id
      frontmatter {
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        seo {
          extra {
            keyName
            name
            value
            relativeUrl
          }
          description
          robots
          title
        }
        og_image {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        twitter_image {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;


const Home = props => {
  const { t } = useTranslation()
  let imgsHome = props.data.home;

  const HomePageImg = (props) => {
    var i, result;
    for (i = 0; result == null && i < props.images.edges.length; i++) {
      if (props.images.edges[i].node.name === props.imageName) {
        const image = getImage(props.images.edges[i].node.childImageSharp);
        const bgImage = convertToBgImage(image);
        return (
          <div className="img-box">
            <BackgroundImage
              Tag="div"
              className="img-bg"
              // style={{width: '100%', height: '100%'}}
              // Spread bgImage into BackgroundImage:
              {...bgImage}
              preserveStackingContext
              backgroundColor={`#212121`}
            />
          </div>
        );
      }
    }
    return null;
  }

  return (
    <Layout {...props}>
      <Header {...props} site={props.pageContext.site} page={props.pageContext} />
      <div id="content" className="site-content home">
        <main id="main" className="site-main inner">

          <div className="container">
            <Link className="box box__l appartement" to={`/l-appartement`}>
              <div className="relative">
                <div className="label">{t("__L'appartement")}</div>
                <HomePageImg images={imgsHome} imageName={`appartement`} />
              </div>
            </Link>
            <Link className="box box__h autour" to={`/autour-de-moriani`}>
              <div className="relative">
                <div className="label">{t("__Autour de Moriani")}</div>
                <HomePageImg images={imgsHome} imageName={`autour`} />
              </div>
            </Link>
            <Link className="box box__l info" to={`infos-pratiques`}>
              <div className="relative">
                <div className="label">{t("__Infos pratiques")}</div>
                <HomePageImg images={imgsHome} imageName={`infos`} />
              </div>
            </Link>
            <Link className="box box__l reservation" to={`/reservation-location`}>
              <div className="relative">
                <div className="label">{t("__Réservation")}</div>
                <HomePageImg images={imgsHome} imageName={`reservation`} />
              </div>
            </Link>
            <Link className="box box__l reglement" to={`/reglement-interieur`}>
              <div className="relative">
                <div className="label">{t("__Règlement intérieur")}</div>
                <HomePageImg images={imgsHome} imageName={`reglement`} />
              </div>
            </Link>
            <Link className="box box__l contact" to={`/contact`}>
              <div className="relative">
                <div className="label">{t("__Contact")}</div>
                <HomePageImg images={imgsHome} imageName={`contact`} />
              </div>
            </Link>
            <Link className="box box__l plan-acces" to={`/plan-acces`}>
              <div className="relative">
                <div className="label">{t("__Plan d'accès")}</div>
                <HomePageImg images={imgsHome} imageName={`plan-acces`} />
              </div>
            </Link>
          </div>

        </main>
        <Footer {...props} site={props.pageContext.site} page={props.pageContext} image={_.get(props, 'pageContext.site.siteMetadata.header.background_img', null)} />
      </div>
    </Layout>
  );
}

export default Home
